<template>
    <div>
        <Header :instanceInfo="instanceInfo" />
        <div class="container">
            <div class="col-md-12">
                <h2 class="pink p-block">
                    TERMS OF SERVICE
                </h2>
            </div>
            <div class="col-md-12 p-block">
                <p>These are the Terms of Service of {{ instanceInfo?.name }}, a service offered by <span class="rtl">{{ companyName }}</span> {{ instanceInfo?.companyRegisterInfo }} ('we', 'us'
                    or 'our(s)'). We advise you to carefully read the Terms of Service, so you fully understand the rights
                    and obligations you have in relation to your use of the Service.</p>
                <h3>1. Definitions</h3>
                <p>
                    1.1 In these Terms of Service, the following terms, indicated with a capital, whether single or plural,
                    will have the following meaning: <br>
                    <b>Account - </b> your personal environment on our Website with which you can access and use the
                    Service;<br>
                    <b>Agreement - </b> the agreement between you and us pertaining to the use of the Service;<br>
                    <b>Content - </b> all information, data, material or other content, including but not limited to
                    pornographic content, graphic visual depictions of sexual activity and nudity, we made available to
                    Users through the Website;<br>
                    <b>IP Rights - </b> all intellectual property rights and associated rights, including copyrights,
                    trademark rights, patent rights, design rights, trade name rights, database rights, and neighbouring
                    rights, as well as rights to knowhow;<br>
                    <b>Service - </b> the digital Fantasy chat service for adults which contains fictional profiles. The
                    Service can be accessed through the Website via a personal Account; <br>
                    <b>User - </b> the natural person using the Service), also referred to as ‘you’ or ´your´;<br>
                    <b>User Content - </b> all information, (personal) data or material or any other content, including but
                    not limited to photos and images, submitted by a User through the Service;<br>
                    <b>Website - </b> the website {{ instanceInfo?.domain }} including all underlying web pages.<br>
                </p>

                <h3>2. General</h3>
                <p>
                    2.1 We are entitled to amend or supplement these Terms of Service at any time. Such amendments shall
                    take effect immediately. The most up-to-date version of the Terms of Service can be found on the
                    Website. Amendments or supplements will be brought to your attention during the use of the Service. If
                    you do not agree to the changes to the Terms of Service, your only option is to terminate the Agreement
                    in accordance with Article 10.2.
                </p>
                <p>
                    2.2 We may assign our rights or delegate any performance under this agreement without your consent. You
                    will not assign your rights or delegate your performance under this agreement without out first
                    obtaining our advanced written consent.
                </p>

                <h3>3. Account</h3>
                <p>
                    3.1 In order to access and use the Service, you must register for an Account on the Website. To
                    register, you must complete the registration process by providing us with accurate information as stated
                    by the registration form. You also must choose a password and a username. You are not allowed to create
                    an Account in the name of a third party.
                </p>
                <p>
                    3.2 You are personally responsible for keeping your Account information secret. You are obliged to
                    notify us immediately as soon as you realize or suspect that your Account has fallen into the hands of
                    unauthorized parties, without prejudice to your own obligation to take effective measures yourself,
                    e.g., changing your password. We are not liable for any loss arising in any way from or related to the
                    unauthorised use of your Account.
                </p>
                <p>
                    3.3 We may suspend, disable, or delete your Account (or any part thereof) if we determine that you have
                    violated any provision of these Terms of Service or that your conduct or User Content would tend to
                    damage our reputation and goodwill. If we delete your Account for the foregoing reasons, you may not
                    re-register for our Services. We may block your email address and Internet protocol address to prevent
                    further registration.
                </p>

                <h3>4. The Service</h3>
                <p>
                    4.1 Only adults who are at least 18-years old and who have reached the age of majority where they live
                    may access this Website. If you do not meet these age requirements, you must not access this Website and
                    must cease the use of the Website and/or Service. By using the Service, you guarantee that that:<br>
                    <br>
                    4.1.1. You are at least 18-years old, have reached the age of majority where you live, and have the
                    legal capacity to enter into an Agreement;<br>
                    4.1.2 You are not offended by pornographic content, including graphic visual depictions of sexual
                    activity and nudity;<br>
                    4.1.3 You acknowledge that the profiles on the Website are fictitious and they have been created only to
                    exchange messages with users and therefore real-life meetings with these fictitious profiles are
                    therefore not possible;<br>
                    4.1.4 You access the Service voluntarily and for your own personal enjoyment;<br>
                    4.1.5 Your use of the Service is not prohibited or restricted by your jurisdiction;<br>
                    4.1.6 You have complied with and will continue to comply with the laws of the jurisdiction from which
                    you access the Service;<br>
                    4.1.7 All User Content that you submit when you register for an Account is accurate, and you will
                    promptly update any information and data that you provide that later becomes inaccurate;<br>
                </p>
                <p>
                    4.2 The Service is provided on an “as-is” and “as available” basis. We do not warrant that access to, or
                    use of, the Service shall be uninterrupted and error free. We expressly exclude any and all explicit and
                    tacit guarantees, undertakings and indemnifications, of any kind whatsoever, including but not limited
                    to those with respect to the quality, safety, lawfulness, integrity and correctness of the Service.
                </p>
                <p>
                    4.3 We accept no responsibility whatsoever for any decisions made by you based on the Content in the
                    Service.
                </p>
                <p>
                    4.4 The Website may contain advertisements, promotions, or links to other websites, resources, and
                    purchase opportunities provided by third parties. We provide these advertisements, promotions, and links
                    to you for your information only. If you access these advertisements, promotions, or links, you may be
                    directed to third-party websites. These third-party websites will have their own Terms of Service as
                    well as and privacy and security policies, which may differ from ours. Our display of advertisements,
                    promotions, or links to third-party websites does not constitute an endorsement by us of any of the
                    third-party content, information, websites, or resources provided.
                </p>

                <h3>5. User Content</h3>
                <p>
                    5.1 You may post User Content when using the Service. However, you must not post User Content that is
                    unlawful and/or wrongful, including User Content that:<br>
                    <br>
                    5.1.1 Contains expletives or language that could be considered offensive or is likely to harass, upset,
                    intimidate, embarrass, alarm, or annoy any other person;<br>
                    5.1.2 Is obscene or otherwise may offend human dignity (including bestiality, child pornography, and
                    incest);<br>
                    5.1.3 Is abusive, insulting, or threatening, or that promotes or encourages racism, sexism, hatred,
                    bigotry, or physical harm of any kind against any group or individual;<br>
                    5.1.4 Requests money from, or is intended to otherwise defraud, other Users of the Service;<br>
                    5.1.5 Encourages any illegal activity, including prostitution, terrorism, inciting racial hatred, or the
                    submission of which in itself amounts to committing a criminal offense;<br>
                    5.1.6 Is defamatory, libelous, or promotes information that is false or misleading, or otherwise
                    objectionable;<br>
                    5.1.7 Relates to commercial activities, including sales, competitions, sweepstakes, advertising, pyramid
                    schemes, links to other websites, or premium line telephone numbers;<br>
                    5.1.8 Involves the transmission of "junk mail," "chain letters," or "spam" (or "spimming," "phishing,"
                    "trolling," or similar activities);<br>
                    5.1.9 Shows another person, which was created or distributed without that person’s consent;<br>
                    5.1.10 Contains restricted or password only access pages, or hidden pages or images (those not linked to
                    or from another accessible page);<br>
                    5.1.11 Provides material that exploits people in a sexual, violent, or (other) illegal way, or solicits
                    personal information from anyone under 18-years old or the age of majority;<br>
                    5.1.12 Provides instructional information about illegal activities, including making or buying illegal
                    weapons or drugs; violating someone’s privacy; or providing, distributing, or creating computer
                    viruses;<br>
                    5.1.13 Contains any spyware; adware; malware; ransomware; viruses; corrupt files; worm programs or other
                    malicious code designed to interrupt, damage, or limit the functionality of or disrupt any software,
                    hardware, telecommunications, networks, servers, or other equipment; Trojan horse; or any other material
                    designed to damage, interfere with, wrongly intercept, or expropriate any data or personal information
                    whether from the Website or otherwise;<br>
                    5.1.14 Provides information or data you do not have a right to make available under law or under
                    contractual or fiduciary relationships, including inside, proprietary, or confidential information;<br>
                    5.1.15 Disrupts the normal flow of dialogue, causes a screen to "scroll" faster than other users can
                    type, or otherwise negatively affects other users’ ability to engage in real time exchanges;<br>
                    5.1.16 Solicits passwords or personally identifying information for commercial or unlawful purposes from
                    other users, or distributes another person’s personal information without his or her permission;<br>
                    5.1.17 Offers money or other consideration in exchange for sex;<br>
                    5.1.18 Promotes an illegal or unauthorized copy of another person’s copyrighted work, including
                    providing pirated computer programs or links to them, providing information to circumvent
                    manufacture-installed copy-protect devices, or providing pirated images, audio, or video, or links to
                    pirated images, audio, or video; or<br>
                    5.1.19 Itself, or the posting of which, infringes any third party’s rights, including
                    intellectual-property rights and rights of privacy and publicity.<br>
                </p>
                <p>
                    5.2 The Service is for personal use only. You will not use the Service for any commercial endeavours,
                    including (1) advertising or soliciting any other user to buy or sell any products or services that we
                    do not offer, or (2) soliciting others to attend parties or other social or networking functions for
                    commercial purposes.
                </p>
                <p>
                    5.3 We may remove, edit, limit, or block access to any User Content that you upload or submit to the
                    Website at any time without notice. We are not required to display your User Content, check the accuracy
                    of User Content, or monitor your or other Users’ use of the Service. If we become aware of a breach of
                    this Article 4, we may take appropriate legal action against you, including removing the User Content
                    from the Website and terminating or suspending your Account.
                </p>

                <h3>6. Interaction with other Users</h3>
                <p>
                    6.1 You can simulate real life discussion and roll playing with fictional profiles in order to fulfil
                    the applicable fantasy but it's not possible to interact with other Users of the Service.
                </p>

                <h3>7. Digital Fantasy chat service</h3>
                <p>
                    7.1 The Service is a digital Fantasy chat service for adults. The profiles are solely provided for the
                    entertainment of Users. The interaction between the User and other profiles may simulate real life
                    discussion and roll playing in order to fulfil the applicable fantasy. However, the Service is <span
                        style="color:red;">not a dating platform</span>, and it is <span style="color:red;">not
                        possible</span> to meet the fictional profiles in person.
                </p>
                <p>
                    7.2 The Website is solely designed for adult pleasure and to simulate realistic role playing by chatting
                    within a secure digital environment with animators who are not identified separately for the
                    entertainment of the user.
                </p>
                <p>
                    7.3 The Website uses fictitious profiles for testing behavioural/social studies and for digital fantasy
                    chat purposes. The profiles posted on the Website are fictitious and are associated with this digital
                    Fantasy chat service.
                </p>
                <p>
                    7.4 This digital Fantasy chat service is part of our efforts to stimulate conversation with Users to
                    encourage further and broader participation in the Services.
                </p>
                <p>
                    7.5 Messages from our digital Fantasy chat service may be automatically generated with no human
                    involvement, and third-party contractors hired or contracted by us may generate messages or
                    correspondence from then on.
                </p>
                <p>
                    7.6 You acknowledge that the Content contained in the digital Fantasy chat service profiles do not
                    pertain to any actual person but are included for digital entertainment purposes only. Nothing contained
                    in any digital Fantasy chat service profile is intended to describe or resemble any real person-living
                    or dead. Any similarity between digital fantasy user profile descriptions and any person is purely
                    coincidental.
                </p>
                <p>
                    7.7 On one or more occasions, digital fantasy profiles may contact users through computer-generated
                    messages to encourage further or broader participation in the Service. These messages may be transmitted
                    to multiple Users at the same time.
                </p>
                <p>
                    7.8 You acknowledge that no physical meeting will ever take place between you and the individuals
                    providing our digital Fantasy chat service and that the exchange of messages is for digital
                    entertainment purposes, as well as to encourage further or broader participation in the Service. We do
                    not guarantee that you will receive a response to any message you send to a digital fantasy user or any
                    other User.
                </p>

                <h3>8. IP Rights</h3>
                <p>
                    8.1 We and/or our licensors retain all IP Rights related to the Service, the Website and the Content.
                    Nothing in the Agreement constitutes the transfer of any IP Rights from us to you.
                </p>
                <p>
                    8.2 You retain the IP Rights relating to your User Content. You acknowledge and accept that by making
                    the User Content available through the Service, you automatically and free of charge grant us an
                    unlimited, worldwide, irrevocable, cost free, sublicensable and transferable right to reproduce,
                    distribute, redistribute, modify, translate, adapt, prepare derivative works, display and otherwise use
                    all or part of the User Content, by any and all means, to the extent that this is required in the
                    context of providing the Service and/or our business activities.
                </p>
                <p>
                    8.3 You represent and warrant that you have all rights to grant the license as laid down in Article 8.2,
                    without infringing or violating any third-party rights, including but limited to, any privacy rights,
                    publicity rights, IP Rights or any other proprietary rights. You indemnify us against any loss and costs
                    arising from and/or related to third party claims based on the argument that the User Content made
                    available by you using the Service in any manner infringes any third-party rights (including though not
                    limited to any IP Rights) and/or is otherwise wrongful.
                </p>
                <a id="pricing"></a>
                <h3>9. Payment &amp; reflection period</h3>
                <p>
                    9.1 Upon submitting your payment details your subscription will become active immediately and your card
                    will be charged instantly.
                    <br>
                    - 3 Days Trial: You will be charged GBP/1,99 / EUR/1,99 / USD/1,99 / CAD/1,99 / AUD/1,99 / NZD/1.99 / JPY/380 upon
                    subscribing based on your country.
                    If you don't cancel your trial subscription within the first 3 days your membership will be
                    automatically renewed
                    at GBP/49,00 / EUR/49,00 / USD/59,00 / CAD/89,00 / AUD/89,00 / NZD/99,00 / JPY/7900 every 30 days.
                </p>
                <p>
                    9.2 Purchased credits lose their validity 1 month after they are purchased. When spending credits, the
                    credits purchased earliest are spent before credits purchased more recently.
                </p>
                <p>
                    9.3 If you initiate a chargeback or otherwise reverse a payment made by you using your chosen payment
                    method which is invalid, we may terminate your Account and block your access to the Service.
                </p>
                <p>
                    9.4 Credits that are purchased and not used, can be refunded within 14 days. If we give you a refund, we
                    will issue the refund in the form of a credit to the credit card you used for your purchase. We will not
                    issue refunds in the form of cash, check, or free services unless required by law. Download and fill in
                    the following document: <a href="/Revocation-form.pdf">Revocation form</a> and sent an email to
                    {{ instanceInfo?.supportEmail }} – We will handle your request within 5 working days.
                </p>
                <p>
                    9.5 All credits and memberships are personal and may not be transferred to other persons or Users.
                </p>
                <p v-if="instanceInfo.displayReminderInfo">
                    9.6 Customer Billing Assurance: The customer will never be charged beyond the agreed-upon amount during subscription.
                    For trial periods lasting 3 days, an email will be sent 24 hours before expiry, offering a cancellation link.
                    After subscription, the merchant will promptly email essential details, including website name, subscription
                    price, period, connection identifiers, renewal date, frequency, and customer's e-mail address. The email will
                    also contain an easy "unsubscribe" link.
                </p>
                <h3>10. Termination</h3>
                <p>
                    10.1 The Agreement is entered into as of the date that User has accepted these Terms of Service and will
                    remain in full force and effect until terminated in accordance herewith.
                </p>
                <p>
                    10.2 You may at any time, and without notice, terminate the Agreement by deleting your Account. Your
                    Account will then be immediately deleted, and you will receive a confirmation thereof. Deleting your
                    Account is in any case permanent and also includes your User Content.
                </p>
                <p>
                    10.3 Notwithstanding any other rights, we have the right to temporarily or permanently revoke or suspend
                    your access to the Service including if you violate these Terms of Service, any applicable laws and
                    regulations or otherwise act unlawfully.
                </p>

                <h3>11. Liability</h3>
                <p>
                    11.1 Our aggregate liability arising out of or related to the Agreement, whether in contract, tort or
                    otherwise, shall be limited to the amount actually paid out by the insurer due to the event giving rise
                    to damages or, if no payment is made by the insurer, to an amount equal to the fees paid by you under
                    the Agreement during the six (6) month period preceding the date on which the event giving rise to
                    damages occurred, with a maximum of € 500,- (five hundred euros).
                </p>
                <p>
                    11.2 In no event shall we have any liability for any indirect loss, consequential loss, loss and/or
                    damage of (User) Content, loss of profit and loss of revenue, loss of business, loss of anticipated
                    savings, or any other similar financial loss or loss of goodwill or reputation, damage as a result of
                    claims from third parties or other incidental, indirect, punitive or exemplary damages of any kind, is
                    excluded.
                </p>
                <p>
                    11.3 The limitations of liability apply collectively to our affiliated companies, their management,
                    directors, employees, representatives and legal successors, who may at all times rely upon the
                    provisions of this article for their own benefit.
                </p>
                <p>
                    11.4 No right to damages shall exist unless you notify us in writing of the damages promptly after it
                    has arisen. Any claim for damages against us shall become extinguished by the mere lapse of twelve (12)
                    months after the claim has come into being.
                </p>

                <h3>12. Questions or complaints</h3>
                <p>
                    12.1 In the event of a question or complaint, Users can send an email to
                    <a :href="'mailto:' + instanceInfo?.supportEmail">{{ instanceInfo?.supportEmail }}</a>.
                    In the event of a submitted
                    complaint, we will assess the complaint and, in the event the complaint is valid, we may decide to a
                    (partial) refund of purchased credits.
                </p>

                <h3>13. Applicable Law and Competent Court</h3>
                <p>
                    13.1 These Terms of Service and the Agreement are construed in accordance with and shall be governed by
                    and interpreted in accordance with the law of United Kingdom.
                </p>
                <p>
                    13.2 Parties agree that all actions and/or proceedings arising under or in connection to these Terms or
                    the Agreement shall exclusively be brought before the competent court in United Kingdom.
                </p>
                <p>
                    13.3 This Article shall not affect the protection which you enjoy under the mandatory law of your
                    country of residence
                </p>
                <h3>14. Subscription cancellation</h3>
                <p>
                    Login in the <router-link to="/login">Portal</router-link> and then go to
                    <router-link to="/settings">Settings</router-link> and click "Cancel my subscription".
                    Alternatively you can use <router-link to="/contact">Contact Form</router-link>, provide your
                    data (used when subscribing)
                    and submit message to our Customer Service or send email to
                    <a :href="'mailto:' + instanceInfo?.supportEmail">{{ instanceInfo?.supportEmail }}</a>
                </p>
            </div>
        </div>
        <Footer :instance-info="instanceInfo" :company-name="companyName" v-bind="$attrs"/>
    </div>
</template>

<script>
import Header from '@/app/components/Header';
import Footer from '@/app/components/Footer';

export default {
    name: 'Terms',
    components: { Header, Footer },
    props: {
        instanceInfo: {
            type: Object,
            required: false,
        },
        companyName: {
          type: String,
          required: false,
        },
    },
};
</script>
